import { Nav, Container, Navbar, Dropdown } from "react-bootstrap";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import "./Header.css";
import { useCollection } from "../hooks/useCollection";
import { useEffect, useState } from "react";

export default function Header() {
	const { documents } = useCollection("users");
	const { user } = useAuthContext();
	const [active, setActive] = useState(false);
	const { logout } = useLogout();

	useEffect(() => {
		if (user) {
			setActive(documents.find((u) => (u.id = user.uid)));
		}
	}, [documents, user]);

	return (
		<header>
			<Navbar expand='md'>
				<Container fluid>
					<div className='header-brand'>
						<Navbar.Brand href='/'>FRIEDA SILVA</Navbar.Brand>
					</div>
					<div>
						{user ? (
							<Dropdown>
								<Dropdown.Toggle
									variant='success'
									id='dropdown-basic'
									style={{ fontSize: ".7em", marginRight: "3.7em" }}
								>
									{user.displayName}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{active && active.admin === true && (
										<Dropdown.Item href='/create'>Add Pieces</Dropdown.Item>
									)}

									<Dropdown.Item onClick={logout} href='#'>
										Log Out
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						) : (
							<Nav.Link className='header-login' href='/Login'>
								Login
							</Nav.Link>
						)}
					</div>
				</Container>
			</Navbar>
		</header>
	);
}
