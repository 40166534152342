import { useCollection } from "./hooks/useCollection";
import { useAuthContext } from "./hooks/useAuthContext";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import AlertMsg from "./components/AlertMsg";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Gallery from "./pages/gallery/Gallery";
import GalleryDetails from "./pages/gallery/GalleryDetails";
import Create from "./pages/create/Create";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Contact from "./pages/contactus/Contact";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import { ToastContainer } from "react-toastify";

function App() {
	const { documents } = useCollection("infoRequest");
	const myinfoRequest = documents.filter((r) => r.status === 1);
	const { authIsReady, user } = useAuthContext();
	// const ADMIN = process.env.REACT_APP_ADMIN_USER;
	const ADMIN1 = process.env.REACT_APP_ADMIN_ME;

	return (
		<div className='App'>
			{authIsReady && (
				<>
					<Header />
					{user && user.uid === ADMIN1 && myinfoRequest && (
						<AlertMsg infoRequest={myinfoRequest} />
					)}
					{/* <Main> */}
					<Routes>
						<Route path='/' exact={true} element={<Home />} />
						<Route path='/about' element={<About />} />
						<Route path='/gallery' element={<Gallery />} />
						<Route path='/gallery/:id' element={<GalleryDetails />} />
						<Route path='/contact' element={<Contact />} />
						<Route path='/create' element={<Create />} />
						<Route path='/login' element={<Login />} />
						<Route path='/signup' element={<Signup />} />
						<Route path='*' element={<NotFound />} />
					</Routes>
					{/* </Main> */}
					<Footer />
				</>
			)}
			<ToastContainer />
		</div>
	);
}

export default App;
